import { http, authHeader } from '@/http-commons';

class VentaDataService {
    create( venta, productCart ){
        const cartDTO = { venta, productCart };
        return http.post('api/v1/venta/create', cartDTO, { headers : authHeader() });
    }
    table( params ){
        return http.get('api/v1/venta/table', { params, headers : authHeader() });
    }
    show( ventaId ){
        return http.get(`api/v1/venta/show/${ventaId}`, {headers : authHeader() });
    }
    update( ventaId, venta ){
        return http.put(`api/v1/venta/update/${ventaId}`, venta, { headers : authHeader() });
    }
    delete( ventaId ){
        return http.delete(`api/v1/venta/delete/${ventaId}`, { headers: authHeader() });
    }
    detalleVenta( ventaId ){
        return http.get(`api/v1/venta/jsonVenta/${ventaId}`, { headers: authHeader() });
    }
    ultimateSell(){
        return http.get(`api/v1/venta/ultimateSell`, { headers: authHeader() });
    }
    // Venta de traslado.
    ventaTrasladoEnviado( sucursalRecibeId ){
        return http.get(`api/v1/venta/finvVentaTrasladoEnviado/${sucursalRecibeId}`, { headers: authHeader() });
    }
    downloadVentaExcelByDay( params ){
        return http.get(`api/v1/venta/downloadVentaExcelByDay`, { params, headers: authHeader(), responseType: 'blob' });
    }
    
    findByCreditClient(clientId){
        return http.get(`api/v1/venta/findByCreditClient/${clientId}`, { headers: authHeader() });
    }

    findByCashRegister(cashRegisterId){
        return http.get(`api/v1/venta/findVentaByCashRegister/${cashRegisterId}`, { headers: authHeader() });
    }
    
    downloadSellDebts( params ){
        return http.get(`api/v1/venta/downloadSellDebts`, { params, headers: authHeader(), responseType: 'blob' });
    }
    
    downloadTicket( ventaId ){
        return http.get(`api/v1/venta/pdf/${ventaId}`, { headers: authHeader(), responseType: 'blob' });
    }
    printSellDebts( params ){
        return http.get(`api/v1/venta/printSellDebts`, { params, headers: authHeader(), responseType: 'blob' });
    }
    printTicket( ventaId, params ){
        return http.get(`api/v1/venta/printTicket/${ventaId}`, { params, headers: authHeader(), responseType: 'blob' });
    }
    printSell(params){
        return http.get(`api/v1/venta/printSell`, { params, headers: authHeader(), responseType: 'blob' });
    }
    cancel( ventaId ){
        return http.post(`api/v1/venta/cancel/${ventaId}`, { headers: authHeader() });
    }

}
export default new VentaDataService();